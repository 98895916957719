import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'cosgrid-clipboard-text',
    templateUrl: './clipboard-text.component.html',
    styleUrls: ['./clipboard-text.component.scss'],
})
export class ClipboardTextComponent implements OnInit {
    @Input() value: any;
    copied: boolean = false;
    constructor() { }

    ngOnInit(): void {
        if (Array.isArray(this.value)) {
            this.value = this.value.join()
        }
    }
    copyClick() {
        this.copied = true;
        setTimeout(() => (this.copied = false), 2000);
    }
}
