
import { ConfirmDialog } from './../../../shared/components/confirm.dialog/confirm.dialog.component';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { JoyrideService } from 'ngx-joyride';
import { Subscription, Observable, Subject } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'app/modules/auth/services/authentication.service';
import { CommonService } from 'app/shared/services/core/common.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, DialogPosition } from '@angular/material/dialog';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { CustomerListService } from 'app/shared/services/comp/customer.list.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { GlobalService, UserService } from 'app/shared/services/core';
import { SearchFeaturesComponent } from 'app/core/components/header/components/search-features/search-features.component';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import { Network } from 'app/shared/model/network';
import { NetworkListService } from 'app/store/services/network-list.service';
import { DeviceListService } from 'app/shared/services/comp';
import { delay, takeUntil } from 'rxjs/operators';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';
import { SETTING_ACTION } from 'app/store/actions/settings.action';
import { AlertService } from 'app/shared/services/misc';

declare let $: any;

interface notificationHeader {
    status: string;
    deviceName: string;
    alarm: string;
}

export const _filter = (opt: string[], value: string): string[] => {
    const filterValue = value.toLowerCase().replace(/\s/g, '');
    return opt.filter((item) => item.toLowerCase().replace(/\s/g, '').includes(filterValue));
};

@Component({
    moduleId: module.id,
    selector: 'cosgrid-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    currentUrl: string = ''
    selectedButton: { notification: boolean, support: boolean, setting: boolean } = { notification: false, support: false, setting: false }
    hideLogout: boolean = true;
    user: any;
    selectedNetwork: Network = {
        networkName: '',
    };
    private destroy$ = new Subject<void>();

    notifications: notificationHeader[] = [];
    searchText: string;
    searchDialogId: string;
    isSuperAdmin: boolean = this.global.isSuperAdmin();
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger; // help
    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger; // search bar
    @ViewChild('notificationMenuTrigger') notificationMenuTrigger: MatMenuTrigger;

    isTenantSelected: boolean = false;
    featureGroupOptions: Observable<any[]>;
    searchWidth = 180;
    seacrhInput;
    selectedNetworkSub: Subscription;
    tenantSubscription: Subscription;
    socialUser: any;
    vpnDashboard;

    selectedDashboard = {
        name: '',
        icon: ''
    }
    ourDashboards = [
        {
            name: 'SD-WAN',
            icon: 'sdwan-logo',
        },
        {
            name: 'Zero Trust',
            icon: 'mza-logo'
        },
        {
            name: 'NDR',
            icon: 'ndr-dash-logo'
        },
        // {
        //     name: 'IOT-Gateway',
        //     icon: 'sdwan-logo'
        // }
    ]

    constructor(
        private global: GlobalService,
        private authservice: AuthenticationService,
        private commonservice: CommonService,
        private router: Router,
        private hotKeyService: HotkeysService,
        private customerListService: CustomerListService,
        private deviceListService: DeviceListService,
        private joyrideService: JoyrideService,
        private dialog: MatDialog,
        private store: Store<CosgridAppStore>,
        private networkListService: NetworkListService,
        private encryptedLocalStorage: EncryptedLocalStorageService,
        private userService: UserService,
        private social: SocialAuthService,
        private alertService: AlertService,
        private activatedRoute: ActivatedRoute,
    ) {
        this.store
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                this.vpnDashboard = value.setting?.vpnDashboard;
                console.log("vpn dashboard", this.vpnDashboard);

            });
        this.hotKeyService.add(
            new Hotkey('s', (event: KeyboardEvent): boolean => {
                this.search();
                return false; // to prevent bubbling
            }),
        );

        this.user = { username: 'User' };
        this.global.isUserLoggedIn.subscribe((value) => {
            this.hideLogout = !value;
            this.getUserValues()
        });

        this.commonservice.UITourTransfer.subscribe(() => {
            this.startTour();
        });
        this.store.select(state => (state.availableDashboardSlice)).subscribe((value) => {

            this.selectedDashboard.name = value.dashboardSettings.currentDashboard
            this.selectedDashboard.icon = value.dashboardSettings.dashboardIcon
        })
        this.commonservice.profileUpdatedEmitter.pipe(takeUntil(this.destroy$)).subscribe(() => {
            console.log("emitted");

            this.getUserValues()
        })
    }

    ngOnInit() {
        // this is for hilighting setting button when click
        this.currentUrl = this.router.url; // Get the current URL initially
        // Subscribe to router events to detect URL changes
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.urlAfterRedirects;
                console.log(this.currentUrl);

                if (this.currentUrl.startsWith('/settings'))
                    this.selectedButton.setting = true;
                else
                    this.selectedButton.setting = false;
            }
        });

        if (this.currentUrl.startsWith('/settings'))
            this.selectedButton.setting = true;
        else
            this.selectedButton.setting = false;


        this.notificationMenuTrigger?.menuOpened.subscribe(() => {
            console.log('Menu opened');
        });

        this.notificationMenuTrigger.menuClosed.subscribe(() => {
            console.log('Menu closed');
        });

        this.subscribeToisTenantSelected();
        this.subscribeToSelectedNetwork();
        this.subscribeToSearchText();
        this.social.authState.subscribe((user) => {
            this.socialUser = user;
        });
    }

    getUserValues() {
        this.user = this.encryptedLocalStorage.getItem('currentUser');
        const tenant = this.customerListService.getTenantName();
        const userInfo = this.userService.getUserInfo(this.user?.email);
        this.user = {
            ...this.user,
            name:
                this.user?.firstName || this.user?.lastName
                    ? `${this.user?.firstName} ${this.user?.lastName}`
                    : userInfo.name,
            companyName: tenant && tenant !== 'undefined' ? tenant : userInfo.companyName,
        };
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        try {
            this.selectedNetworkSub.unsubscribe();
            this.tenantSubscription.unsubscribe();
        } catch (err) { }
    }

    subscribeToSearchText() {
        this.global.searchText.subscribe((res) => {
            this.searchText = res;
        });
    }

    gotoHome() {
        if (this.isSuperAdmin) {
            this.router.navigateByUrl('/superAdmin/tenant');
        } else {
            this.router.navigateByUrl('/dashboard');
        }
    }

    changeDashboard(name: string, icon: string) {
        let settingsValue;
        this.store.select(state => (state.settingSlice)).pipe(takeUntil(this.destroy$)).subscribe(value => {
            settingsValue = value.setting

        })
        if (this.selectedDashboard.name == name) {
            this.alertService.snack(`You are alredy in the ${name} Dashboard`)
            return
        }
        if (this.router.url.includes('/superAdmin/tenant'))
            return
        switch (name) {
            case 'SD-WAN':
                settingsValue.vpnDashboard = false
                this.emptyDashboardValues()
                this.store.dispatch({
                    type: SETTING_ACTION,
                    payload: settingsValue
                })
                this.selectedDashboard.name = name
                this.selectedDashboard.icon = icon
                break;
            case 'Zero Trust':
                settingsValue.vpnDashboard = true
                this.emptyDashboardValues()
                this.store.dispatch({
                    type: SETTING_ACTION,
                    payload: settingsValue
                })
                this.selectedDashboard.name = name
                this.selectedDashboard.icon = icon
                break;
            case 'NDR':
                this.alertService.snack(`Please Subscribe to use ${name} Dashboard`)
                return;
            case 'IOT-Gateway':
                this.selectedDashboard.name = name
                this.selectedDashboard.icon = icon
                this.router.navigate(['/iot-gateway'])
                return;
            default:
                this.alertService.snack('You didn\'t select any dashboard')
        }
        if (!this.activatedRoute.snapshot.routeConfig?.path.includes('/dashboard'))
            this.router.navigate(['/dashboard'])
    }

    emptyDashboardValues() {
        this.commonservice.changeDashboardEmitter.emit('dashboard changed')
    }

    subscribeToisTenantSelected() {
        try {
            //  Adding 200ms delay to avoid 'Expression changed after it was checked' error
            this.tenantSubscription = this.store
                .select((slices) => slices.superAdminSlice.isTenantSelected)
                .pipe(delay(200))
                .subscribe((res) => {
                    if (res) {
                        // to avoid making call in superadmin page in aplication startup
                        if (!this.router.url.includes('superAdmin')) {
                            this.getNetworks();
                        }
                    }
                    this.isTenantSelected = res;
                    console.log("tenant selected", this.isTenantSelected);

                });
        } catch (err) {
            console.log(err, "tenant not found");

        }
    }

    subscribeToSelectedNetwork() {
        this.selectedNetworkSub = this.store
            .select((slices) => slices.networkSlice.selectedNetwork)
            .subscribe((res) => {
                if (res) {
                    // getdevicelist call is in changeNetwork() cuz network cannot be changed except here
                    // device selection deleted when the user literally toggles the selection and not when the network value changes
                    this.selectedNetwork = res;
                }
            });
    }

    getDeviceList(): any {
        this.deviceListService
            .getDeviceList({
                tenant_id: this.customerListService.getTenantId(),
                network_id: this.selectedNetwork.id,
            })
            .subscribe((data) => {
                let r: any = data;

                this.deviceListService.dispatchAllDevicesToStore(r);
            });
    }

    getNetworks() {
        this.commonservice.fetchNetworkList(this.customerListService.getTenantId()).subscribe(
            (res: Array<Network>) => {
                this.networkListService.dispatchAllNetworksToStore(res);
            },
            (err) => { },
        );
    }

    search() {
        let dialogPosition: DialogPosition = {
            top: '50px',
        };
        if (this.dialog.getDialogById(this.searchDialogId)) {
            //already opened
        } else {
            const dialogRef = this.dialog.open(SearchFeaturesComponent, {
                position: dialogPosition,
            });
            this.searchDialogId = dialogRef.id;
            dialogRef.afterClosed().subscribe((res) => {
                this.searchDialogId = '';
                document.getElementById('searchFeatures').blur(); // to remove focus from fake searchbar, so that user does not type in it
                if (res === 'selectDevice') {
                    //devuice not selected
                }
            });
        }
    }

    selectedMenu(menu: string) {
        this.selectedButton.setting = (menu === 'setting');
        if (menu === 'support') {
            this.selectedButton.support = !this.selectedButton.support;
        } else {
            this.selectedButton.support = false;
        }
        if (menu === 'notification') {
            this.selectedButton.notification = !this.selectedButton.notification;
        } else {
            this.selectedButton.notification = false;
        }
    }

    startTour() {
        if (this?.vpnDashboard) {
            this.joyrideService
                .startTour({
                    steps: ['network', 'device', 'notifications', 'profile'],
                    stepDefaultPosition: 'bottom',
                })
                .subscribe(
                    (step) => { },
                    (err) => { },
                    () => {
                        //completed
                    },
                );
        } else {
            this.joyrideService
                .startTour({
                    steps: ['search', 'network', 'device', 'notifications', 'profile'],
                    stepDefaultPosition: 'bottom',
                })
                .subscribe(
                    (step) => { },
                    (err) => { },
                    () => {
                        //completed
                    },
                );
        }
    }

    listenForFocus() {
        this.seacrhInput.addEventListener('focus', (e) => {
            this.searchWidth = 450;
        });

        this.seacrhInput.addEventListener('blur', (e) => {
            this.searchWidth = 180;
        });
    }

    dismissNotifications(e) {
        e.stopPropagation();
        this.notifications = [];
        this.menuTrigger.closeMenu();
    }

    logout() {
        if (this.global.isSuperAdmin()) {
            this.router.navigateByUrl('/superAdmin/tenant');
        } else {
            this.router.navigateByUrl('/dashboard');
        }
        const dialogRef = this.dialog.open(ConfirmDialog, { data: { message: 'Logout' } });
        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                if (this.socialUser) {
                    this.social.signOut();
                    localStorage.removeItem('userEmail');
                }
                this.authservice.logout();
            }
        });
    }

    /**
     * This method is triggered when the device is selected
     */
    deviceSelected(event: string) {
        this.commonservice.deviceSelected.next(true);
    }
}
