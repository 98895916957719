import { SETTING_ACTION } from './../../../store/actions/settings.action';
import { DashboardConfService } from './../../../modules/dashboard/services/dashboard-conf.service';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from './../../../store/app.store';
import { SDWanGatewayUtilService } from 'app/modules/sd-wan-gateway/common/services/sd-wan-gateway.util.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Component, OnInit, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
declare let $: any;
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { JoyrideService } from 'ngx-joyride';
import { MatDialog, DialogPosition } from '@angular/material/dialog';
import { LiveChatSupportComponent } from 'app/shared/components/live-chat-support/live-chat-support.component';
import { CommonService, GlobalService } from 'app/shared/services/core';
import { AlertService } from 'app/shared/services/misc';
import { ChatService } from 'app/shared/services/comp';
import { AuthenticationService } from 'app/modules/auth/services/authentication.service';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';
import { AVAILABLE_DASHBOARD_ACTION } from 'app/store/actions/availableDashboard.actions';
@Component({
    moduleId: module.id,
    selector: 'cossgrid-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss'],
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {
    // items: MenuItem[];
    private destroy$ = new Subject<void>();
    selectedDevice: boolean = false;
    tooltipDisabled: boolean = false;
    NetworkList: any = [];
    hideNetworkSelect: boolean = true;
    hideLogout: boolean = true;
    one: boolean = true;
    two: boolean = true;
    user: any;
    isSuperAdmin: boolean = false;

    // sub = this.commonservice.networkList.subscribe(val => {
    //   this.NetworkList = val;
    //   if (this.NetworkList.length > 0) {
    //     this.hideNetworkSelect = false;
    //   } else { this.hideNetworkSelect = true; }
    // });

    sidebarwidth = 45;
    organizationHierarchy = 0;
    teamsHierachy = 0;
    sidebaropened: boolean = false;

    active = false;
    selectDeviceSub: Subscription;

    optionNames;
    newNotiff: boolean = false;
    newMessages: number = 1;

    newNotiffAdmin: boolean = false;
    newMessagesAdmin: number = 1;

    notiffSubscription: Subscription;
    tenantSubscription: Subscription;
    isTenantSelected: boolean;

    selectedDashboard: string = ''
    ourDashboards = [
        {
            name: 'SD-WAN',
            icon: 'support'
        },
        {
            name: 'Zero Trust',
            icon: 'support'
        }
    ]

    constructor(
        private global: GlobalService,
        private dialog: MatDialog,
        private commonservice: CommonService,
        private router: Router,
        private alertService: AlertService,
        private sdWanGatewayUtilityService: SDWanGatewayUtilService,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
        private joyrideService: JoyrideService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private dashboard: DashboardConfService,
        private store: Store<CosgridAppStore>,
        private chat: ChatService,
        private authservice: AuthenticationService,
        private activatedRoute: ActivatedRoute,
    ) {
        iconRegistry.addSvgIcon(
            'quickStartGuide',
            sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/quickStartGuide.svg '),
        );
        iconRegistry.addSvgIcon(
            'web-filter',
            sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/web-filter.svg'),
        );
    }
    vpnDashboard: boolean;

    ngOnInit() {
        this.store
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                this.vpnDashboard = value.setting?.vpnDashboard;
            });
        this.vpnDashboard ? this.selectedDashboard = this.ourDashboards[1].name : this.selectedDashboard = this.ourDashboards[0].name
        this.subscribeToSelectDevice();
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.router.url.includes('liveChatSupport')) {
                    this.newNotiffAdmin = false;
                }
                if (this.router.url.includes('/device/status')) {
                    this.active = true;
                } else {
                    this.active = false;
                }
            }
        });

        this.selectedDevice = false;

        this.global.isUserLoggedIn.subscribe((value) => {
            this.hideLogout = !value;
            this.user = this.encryptedLocalStorageService.getItem('currentUser');
        });

        this.isSuperAdmin = this.global.isSuperAdmin();
        try {
            let x = this.sdWanGatewayUtilityService.getDeviceId();
            if (x === null || x === '' || x === undefined || x === '-1') {
                this.selectedDevice = false;
            } else {
                this.selectedDevice = true;
            }
        } catch (err) { }
        if (this.isSuperAdmin) {
            this.subscribeToSuperAdminChat();
            this.subscribeToNewNottifAdmin();
            this.subscribeToisTenantSelected();
        } else {
            this.isTenantSelected = true;
            this.subsribeToTenantChat();
            this.subscribeToNewNottif();
        }
    }

    subscribeToSelectDevice() {
        this.selectDeviceSub = this.commonservice.deviceSelected.subscribe((res) => {
            this.selectedDevice = res;
        });
    }

    getDashboardConfig() {
        return new Promise(async (resolve, reject) => {
            try {
                let data: any = await this.dashboard.getDashboardConf().toPromise();

                this.store.dispatch({
                    type: SETTING_ACTION,
                    payload: data.dashboard,
                });
                resolve('');
            } catch (error) {
                reject();
            }
        });
    }

    ngOnDestroy(): void {
        this.selectDeviceSub?.unsubscribe?.();
        this.notiffSubscription?.unsubscribe?.();
        this.tenantSubscription?.unsubscribe?.();
    }

    // For notification (tenant)
    subsribeToTenantChat() {
        this.chat.subscribeToTenantChat();
    }

    subscribeToisTenantSelected() {
        try {
            this.tenantSubscription = this.store
                .select((slices) => slices.superAdminSlice.isTenantSelected)
                .subscribe((res) => {
                    this.isTenantSelected = res;
                });
        } catch (err) { }
    }

    subscribeToNewNottif() {
        this.notiffSubscription = this.chat.tenantChat.subscribe((res) => {
            this.newMessages = res;
            if (this.newMessages <= 0) {
                this.newNotiff = true;
                this.chat.sendDesktopNotification(this.newMessages);
            }
        });
    }


    // For notification (superadmin)
    subscribeToSuperAdminChat() {
        this.chat.subscribeToAllChat();
    }
    subscribeToNewNottifAdmin() {
        this.notiffSubscription = this.chat.AllChats.subscribe((res) => {
            this.newMessagesAdmin = res;
            if (!this.router.url.includes('liveChatSupport')) {
                this.newNotiffAdmin = true;
            }
            if (this.newMessages <= 0) {
                this.chat.sendDesktopNotification(this.newMessagesAdmin).then(() => {
                    this.router.navigateByUrl('tenant-selection/liveChatSupport');
                });
            }
        });
    }

    showDeviceNotSelected() {
        this.alertService.snack('Please Select Device', 'OK', 1000);
    }

    showSelectTenant() {
        this.alertService.snack('Please Select Tenant', 'OK', 1000);
    }

    startTour() {
        this.closeSideBar();
        if (this.vpnDashboard) {
            setTimeout(() => {
                this.joyrideService
                    .startTour({
                        steps: [
                            'home',
                            'microzaccess',
                            'globalMonitor',
                            'logs',
                            'teams',
                            'teamusers',
                            'group',
                            'ndr',
                            'organization',
                            'inventory',
                            'deviceSystem',
                            'download',
                            'users',
                            'webfilter',
                            'securityGroup',
                            'meshServer',
                            'network',
                            'device',
                            'notifications',
                            'profile',
                        ],
                        stepDefaultPosition: 'right',
                    })
                    .subscribe(
                        (step) => { },
                        (error) => { },
                        () => {
                            // this.commonservice.UITourTransfer.emit('start');
                        },
                    );
            }, 500);
        } else {
            setTimeout(() => {
                this.joyrideService
                    .startTour({
                        steps: [
                            'home',
                            'sdWan',
                            'globalMonitor',
                            'logs',
                            'teams',
                            'teamusers',
                            'group',
                            'ndr',
                            'organization',
                            'inventory',
                            'monitor',
                            'systemTools',
                            'configTemplates',
                            'search',
                            'network',
                            'device',
                            'notifications',
                            'profile',
                        ],
                        stepDefaultPosition: 'right',
                    })
                    .subscribe(
                        (step) => { },
                        (error) => { },
                        () => {
                            // this.commonservice.UITourTransfer.emit('start');
                        },
                    );
            }, 200);
        }
    }
    ngAfterViewInit() {
        this.optionNames = document.getElementsByClassName('optionNames') as HTMLCollectionOf<HTMLElement>;
        $('[data-toggle="popover"]').popover('disable');
    }

    toggleSideBar() {
        if (this.sidebaropened) {
            this.closeSideBar();
        } else {
            this.openSideBar();
        }
    }
    openSideBar() {
        this.sidebarwidth = 230;
        this.organizationHierarchy = 30;
        this.sidebaropened = !this.sidebaropened;
    }
    closeSideBar() {
        // setTimeout(() => {
        this.sidebarwidth = 45;
        this.organizationHierarchy = 0;
        this.sidebaropened = !this.sidebaropened;
        // }, 300);
    }
    openLiveChat(ev) {
        let xPos = ev.x;
        let yPos = ev.y;
        // if user click to0 away from the sidemenu ..chat modal will spawn too away from sidemenu.so restrict the space from left to 50px
        if (xPos > 50) {
            xPos = 50;
        }
        let height = 400,
            width = 280;
        let dialogPosition: DialogPosition = {
            top: yPos - height - 30 + 'px',
            left: xPos + 30 + 'px',
        };
        this.chat.unsubscribeToTenantChat();
        this.newNotiff = false;
        const dialogRef = this.dialog.open(LiveChatSupportComponent, {
            position: dialogPosition,
            backdropClass: 'dialogBackdrop',
        });

        dialogRef.afterClosed().subscribe((res) => {
            this.subsribeToTenantChat();
        });
    }

    removeSessionDashboard() {
        this.store.dispatch({
            type: AVAILABLE_DASHBOARD_ACTION,
            payload: {
                dashboardFromPreference: false,
                currentDashboard: '',
                dashboardIcon: ''
            }
        })
    }

    logout() {
        this.authservice.logout();
    }
}
